body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #191919fa; */
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




.button {
  color: black;
  outline: none;
  border: none;
  font-weight: 700;
  border-radius: 5px;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #e6e6e6;
  cursor: pointer;
  margin-right: 1rem;
}

.button:hover {
  color: black;
  background-color: #f5b512;
}

/* hide scrollbar but allow scrolling */
element {
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  overflow-y: scroll;
}

element::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

body {
  font-family: sans-serif;
}





:root {
  --bg: #1a1e24;
  --color: #f5b512;
  --font: Montserrat, Roboto, Helvetica, Arial, sans-serif;
}

.wrapper {
  /* padding: 1.5rem 0;
  filter: url('#goo'); */
}

.button {
  display: inline-block;
  /* text-align: center; */
  background: var(--color);
  color: var(--bg);
  /* font-weight: bold; */
  /* padding: 1.18em 1.32em 1.03em; */
  /* line-height: 1; */
  border-radius: 6px;
  position: relative;
  min-width: 8.23em;
  text-decoration: none;
  /* font-family: var(--font); */
  /* font-size: 1.25rem; */
}

.button:before,
.button:after {
  width: 4.4em;
  height: 2.95em;
  position: absolute;
  content: "";
  display: inline-block;
  background: var(--color);
  border-radius: 50%;
  transition: transform 0.5s ease;
  transform: scale(0);
  z-index: -1;
}

.button:before {
  top: -25%;
  left: 20%;
}

.button:after {
  bottom: -25%;
  right: 20%;
}

.button:hover:before,
.button:hover:after {
  transform: none;
}


/* Demo styles */

/* body {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--bg)
} */


.app-icons img {
    width: 200px !important;
    height: 56px !important;
}
